import React from 'react';

const Services = () => {
    return (
        <>
            <section id='services' className="s-services">
                <div className="row section-header has-bottom-sep" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">What We Do</h3>
                        <h1 className="display-2">We love what we do and combine our knowledge with enthusiasm to go above and beyond for our clients.</h1>
                        <h5>We've got everything you need to launch and grow your business or idea!</h5>
                    </div>
                </div> 
                <div className="row services-list block-1-2 block-tab-full">
                    {/* <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-paint-brush"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Brand Identity</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div> */}

                    {/* <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-group"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Illustration</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div> */}

                    {/* <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-megaphone"></i>
                        </div>  
                        <div className="service-text">
                            <h3 className="h2">Marketing</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div> */}

                    {/* <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-earth"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Web Design</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div> */}

                    {/* <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon">
                            <i className="icon-cube"></i>
                        </div>
                        <div className="service-text">
                            <h3 className="h2">Packaging Design</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div> */}

                    {/* <div className="col-block service-item" data-aos="fade-up">
                        <div className="service-icon"><i className="icon-lego-block"></i></div>
                        <div className="service-text">
                            <h3 className="h2">Web Development</h3>
                            <p>Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. 
                            Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis.
                            Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                            </p>
                        </div>
                    </div> */}
                </div>
                <div className="row section-header has-bottom-sep" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="lead">Don't blindly follow the crowd.</h3>
                        <p className='small'>Let's build something incredible together!</p>
                    </div>
                </div> 
            </section>
        </>
    )
}

export default Services;
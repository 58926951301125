import React from 'react';
// import heroBgJpgImage from '../assets/images/hero-bg.jpg';
import mainPngImage from '../assets/images/main3.png';
import mainMobPngImage from '../assets/images/main5.png';
import { isMobile } from 'react-device-detect';

const data = {
    mediaNetworkLinks: [
        {
            href: "#0",
            className: "fa fa-linkedin",
            ariaHidden:"true",
            labelName: 'LinkedIn',
            url: "https://www.linkedin.com/company/ecorpin",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-github",
            ariaHidden:"true",
            labelName: 'Github',
            url: "https://github.com/Ecorpin-Corp",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-paypal",
            ariaHidden:"true",
            labelName: 'Paypal',
            url: "https://www.paypal.com/paypalme/bhagwansingh5602",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-skype",
            ariaHidden:"true",
            labelName: 'Skype',
            url: "live:hritikr16_1",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-youtube",
            ariaHidden:"true",
            labelName: 'Youtube',
            url: "https://www.youtube.com/@ecorpincorporation6752",
            targetWindow: 'blank' 
        }
    ],
}

const Home = () => {
    return (
        <>
            <section id="home" className="s-home target-section" data-parallax="scroll" data-image-src={isMobile ? mainMobPngImage : mainPngImage} data-natural-width="3000" data-natural-height="2000" data-position-y="center">
                <div className="overlay"></div>
                <div className="shadow-overlay"></div>
                <div className="home-content">
                    <div className="row home-content__main">
                        <h3>Welcome to Ecorpin</h3>
                        <h1>
                            We are a creative group <br/>
                            of freelancers who design <br/>
                            influential brands and <br/>
                            digital experiences.
                        </h1>
                        <div className="home-content__buttons">
                            <a href="#contact" className="smoothscroll btn btn--stroke">
                                Start a Project
                            </a>
                            <a href="#about" className="smoothscroll btn btn--stroke">
                                More About Us
                            </a>
                        </div>
                    </div>
                    <div className="home-content__scroll">
                        <a href="#about" className="scroll-link smoothscroll">
                            <span>Scroll Down</span>
                        </a>
                    </div>
                    <div className="home-content__line"></div>
                </div>
                <ul className="home-social">
                    {
                        data?.mediaNetworkLinks && data?.mediaNetworkLinks?.map((item, index) => <li key={`socialIcons-${index}`}><a href={item.url} target={item.targetWindow}><i className={item.className} aria-hidden={item.ariaHidden}></i><span>{item.labelName}</span></a></li>)
                    }
                </ul> 
            </section>
        </>
    )
}

export default Home;
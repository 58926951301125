import React from 'react';

const Loader = () => {
    return (
        <>
            <div id="preloader">
                <div id="loader">
                    <div className="line-scale-pulse-out">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Loader;
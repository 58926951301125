import React from 'react';
import Slider from "react-slick";

const SlickSlider = ({ data }) => {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnFocus: false,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <Slider {...settings}>
            {
                data && data.map((item, index) => <a key={`client-${index}`} href={item.website} title={item.name} className={item.className} target='blank'><img src={item.image} height={150} width={150} alt="Client Logo" /></a>)
            }
        </Slider>
    );
}

export default SlickSlider;
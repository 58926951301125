import React from 'react';
import loginBannerImage from '../assets/images/hero-bg.jpg';
import { showAppAlert } from '../helpers/commonHelpers';

const style = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
    },
    formSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '35%',
        background: 'teal',
        padding: '10px',
    },
    bannerSection: {
        display: 'flex',
        flexDirection: 'row',
        width: '65%',
        // background: 'seagreen',
        backgroundImage: `url(${loginBannerImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'

    },
    formStyle: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: 'seagreen',
        padding: '20px',
    },
    formInput: {
        display: 'block',
        marginBottom: '0px',
        height: '3rem',
        width: '100%',
        // letterSpacing: '.1rem'
    },
    formParagraph: {
        marginBottom: '0rem',
        padding: '15px',
    },
    formLabel: {
        marginBottom: '0rem',
    }
}

const Administrator = () => {
    const [authState, setAuthState] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const handleOnChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormData({...formData, [name]: value });
    }

    const handleAuthLogin = async (event) => {
        event.preventDefault();
        console.info("Logging user into server :: ");
        setLoading(true);
        if (true) {
            let alertConfig = {
                icon:"success",
                title: "Authenticated Successfully!",
                html: "<span style='color:#39b54a;font-size:18px;font-family:Arial, sans-serif;'>Redirecting to Dashboard!</span>",
            };
            showAppAlert(alertConfig);
            setAuthState(true);
        } else {
            let alertConfig = {
                icon:"error",
                title: "Authentication Failed!",
                html: "<span style='color:red;font-size:18px;font-family:Arial, sans-serif;'>Something Went Wrong!</span>",
            };
            showAppAlert(alertConfig);
            setAuthState(false);
        }
        setLoading(false);
    }

    return (
        <div style={style.container}>
            {/* Authentication Component */}
            {
                !authState && 
                <>
                    <div style={style.bannerSection}></div>
                    <div style={style.formSection}>
                        <center>
                            <h3 style={{ color: 'white' }}>Welcome to <br /> Ecorpin Secure Server</h3>
                        </center>
                        <form style={style.formStyle} onSubmit={handleAuthLogin} noValidate>
                            <p style={style.formParagraph}>
                                <label htmlFor="projectCode" style={style.formLabel}>ProjectCode : </label>
                                <input type="text" name="projectCode" id="projectCode" style={style.formInput} onChange={handleOnChange} required/>
                            </p>

                            <p style={style.formParagraph}>
                                <label htmlFor="loginName" style={style.formLabel}>LoginName : </label>
                                <input type="text" name="loginName" id="loginName" style={style.formInput} onChange={handleOnChange} required/>
                            </p>

                            <p style={style.formParagraph}>
                                <label htmlFor="password" style={style.formLabel}>Password : </label>
                                <input type="password" name="password" id="password" style={style.formInput} onChange={handleOnChange} required/>
                            </p>
                            <button type="submit">{ !loading && 'Sign In!' } { loading && 'Logging In...' }</button>
                        </form>
                    </div>
                </>
            }

            {/* Dashboard Component */}
            {
                authState && 
                <>
                    <div style={{ background: 'teal', color: 'white', width: '100%', height: '100vh' }}>
                        <h1>Ecorpin Dashboard!</h1>
                    </div>
                </>
            }
        </div>
    )
};

export default Administrator;
import React from 'react';

const About = () => {
    return (
        <>
            <section id='about' className="s-about">
                <div className="row section-header has-bottom-sep" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead subhead--dark">Hello There</h3>
                        <h1 className="display-1 display-1--light">We Are Ecorpin</h1>
                        <p className="lead">We build epic realtime interactive experience to blew people's minds</p>
                    </div>
                </div> 
                <div className="row about-desc" data-aos="fade-up">
                    <div className="col-full">
                        <p>
                            At Ecorpin, we solve real problems everyday.
                            Our personalised approach is directed at creating tailored technology experiences that accelerate business growth and provide unbounded scalability to help you serve millions of customers at once.
                            We embrace significant challenges as opportunities to excel and deliver unparalleled value to our partners. 
                        </p>
                    </div>
                </div> 
                <div className="row about-stats stats block-1-4 block-m-1-2 block-mob-full" data-aos="fade-up">
                    <div className="col-block stats__col ">
                        <div className="stats__count">5 &#9733;</div>
                        <h5>Google Reviews</h5>
                    </div>
                    <div className="col-block stats__col">
                        <div className="stats__count">03</div>
                        <h5>Countries</h5>
                    </div>
                    <div className="col-block stats__col">
                        <div className="stats__count">10</div>
                        <h5>Projects Completed</h5>
                    </div>
                    <div className="col-block stats__col">
                        <div className="stats__count">10</div>
                        <h5>Happy Clients</h5> 
                    </div>
                </div> 
                <div className="about__line"></div>
                <div className="row about-desc" data-aos="fade-up">
                    <div className="col-full">
                        <p>
                            Our commitment to excellence drives us continuously to explore fresh ideas and methodologies, ensuring that we consistently provide the highest quality solutions and services.
                            Our development team accomplishes the hardest task with planned analysis implementation in a result-oriented way.
                            <br />
                            <span className="h5">"We inspire our extra ordinary team, and they inspire us right back!" </span>
                        </p>
                    </div>
                </div> 
            </section>
        </>
    )
}

export default About;
import Swal from 'sweetalert2';


/**
 * Show app alert.
 * @param {object} data 
 */
const showAppAlert = (data) => {
    let alertConfig = Swal.mixin(data);
    alertConfig.fire({
        toast: true,
        position: "top-end",
        color: "gray",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
        ...alertConfig,
    });
}

export {
    showAppAlert,
}
import React from 'react';
import { BrowserRouter, Route, Routes,  Navigate } from "react-router-dom";

import Landing from './router/Landing';
import Administrator from './router/Administrator';
// import PageNotFound from './router/PageNotFound';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<Navigate to="/" />} />
				<Route path="/" element={<Landing />} />
				<Route path="/auth" element={<Administrator />} />
				{/* <Route path="*" element={<PageNotFound />} /> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;

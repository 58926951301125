import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc } from "firebase/firestore";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD1hPHyCmEVWilRnPi7X0nKLBkmxU1M2KI",
    authDomain: "ecorpin.firebaseapp.com",
    databaseURL: "https://ecorpin.firebaseio.com",
    projectId: "ecorpin",
    storageBucket: "ecorpin.appspot.com",
    messagingSenderId: "94962189182",
    appId: "1:94962189182:web:381e932a92ebe30942e92f",
    measurementId: "G-2VWDQMEK4P"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function getWebClientData() {
    try {
        const citiesCol = collection(db, 'webClientForm');
        const citySnapshot = await getDocs(citiesCol);
        const cityList = citySnapshot.docs.map(doc => doc.data());
        return cityList;
    } catch (error) {
        console.error("Error getting web client data:", error);
        throw error; // Rethrow the error for handling elsewhere if needed
    }
}

async function setWebClientData(clientData, setLoading, showAppAlert) {
    try {
        const webClientFormCollection = collection(db, 'webClientForm');
        await addDoc(webClientFormCollection, clientData);
        console.log("Client data added successfully!");

        let alertConfig = {
            icon:"success",
            title: "We'll get back to you shortly.",
            html: "<span style='color:#39b54a;font-size:18px;font-family:Arial, sans-serif;'>Welcome to an electrefying journey!</span>",
        };
        setLoading(false);
        showAppAlert(alertConfig);
        return;
    } catch (error) {
        console.error("Error submitting data to firestore:", error);
        let alertConfig = {
            icon:"error",
            title: "Oops.. Something went wrong!",
            html: "<span style='color:#39b54a;font-size:18px;font-family:Arial, sans-serif;'>We'll retry, automatically!</span>",
        };
        setLoading(false);
        showAppAlert(alertConfig);
        throw error; // Rethrow the error for handling elsewhere if needed
    }
}

export {
    app,
    db,
    getWebClientData,
    setWebClientData
}
import React from 'react';
import logoPngImage from '../assets/images/logo.png';

const data = {
    mediaNetworkLinks: [
        {
            href: "#0",
            className: "fa fa-linkedin fa-2x",
            ariaHidden: "true",
            labelName: 'LinkedIn',
            url: "https://www.linkedin.com/company/ecorpin",
            targetWindow: 'blank'
        }, {
            href: "#0",
            className: "fa fa-github fa-2x",
            ariaHidden: "true",
            labelName: 'Github',
            url: "https://github.com/Ecorpin-Corp",
            targetWindow: 'blank'
        }, {
            href: "#0",
            className: "fa fa-paypal fa-2x",
            ariaHidden: "true",
            labelName: 'Paypal',
            url: "https://www.paypal.com/paypalme/bhagwansingh5602",
            targetWindow: 'blank'
        }, {
            href: "#0",
            className: "fa fa-skype fa-2x",
            ariaHidden: "true",
            labelName: 'Skype',
            url: "live:hritikr16_1",
            targetWindow: 'blank'
        }, {
            href: "#0",
            className: "fa fa-youtube fa-2x",
            ariaHidden: "true",
            labelName: 'Youtube',
            url: "https://www.youtube.com/@ecorpincorporation6752",
            targetWindow: 'blank'
        }
    ],
}

const Header = () => {
    const [menuState, setMenuState] = React.useState(false);

    const handleMenuClick = async (event) => {
        event.preventDefault();
        setMenuState(!menuState);
    };

    const styleSheet = {
        navigationContainer: {
            height: '30px',
            width: '30px',
            position: 'absolute',
            top: '20px',
            right: '20px',
            background: 'transparent',
            color: "#33a242",
            zIndex: '800',
            border: 'unset',
        },
        navContent: {
            position: 'relative',
            opacity: '1',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
        },
        headerNavList: {
            fontFamily: 'montserrat-regular, sans-serif',
            fontSize: '1.8rem',
            margin: '3.6rem 0 3rem 0',
            padding: '0 0 1.8rem 0',
            listStyle: 'none'
        },
        headerNav: {
            background: 'white',
            color: 'green',
            fontFamily: 'montserrat-light, sans-serif',
            fontSize: '1.3rem',
            lineHeight: '1.846',
            padding: '3.6rem 2.6rem 3.6rem 2.6rem',
            height: '100%',
            width: '280px',
            position: 'fixed',
            right: 0,
            top: 0,
            zIndex: '700',
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'all 0.5s ease',
            WebkitTransform: 'translateZ(0)',
        },
        mediaNetworkLinks: {
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            listStyle: 'none',
            marginLeft: 'unset',
        },
        footerContent: {
            marginTop: 'auto',
            fontSize: '1.5rem',
            lineHeight: '1.5',
            color: 'green',
        }
    }

    return (
        <>
            <header className="s-header">
                <div className="header-logo">
                    <a className="site-logo" href="index.html">
                        <img src={logoPngImage} alt="Homepage" />
                    </a>
                </div>

                {
                    !!menuState &&
                    <nav style={styleSheet.headerNav} >
                        <button style={styleSheet.navigationContainer} onClick={handleMenuClick} title="close"><i className="fa fa-times-circle fa-2x"></i></button>
                        <div className="" style={styleSheet.navContent}>
                            <div>
                                <h3 style={{ color: '#33a242' }}>Navigation</h3>
                                <ul style={styleSheet.headerNavList}>
                                    <li className="current"><a className="smoothscroll" href="#home" title="home">Home</a></li>
                                    <li><a className="smoothscroll" href="#about" title="about">About</a></li>
                                    <li><a className="smoothscroll" href="#services" title="services">Services</a></li>
                                    <li><a className="smoothscroll" href="#clients" title="clients">Clients</a></li>
                                    <li><a className="smoothscroll" href="#contact" title="contact">Contact</a></li>
                                </ul>
                            </div>
                            <div style={styleSheet.footerContent}>
                                <p>“Based in various Remote Locations in India and on internet. We Explore, Ideate and Collaborate with clients to execute and integrate digital solutions using the latest in technologies.”</p>
                                <ul style={styleSheet.mediaNetworkLinks}>
                                    {
                                        data?.mediaNetworkLinks && data?.mediaNetworkLinks?.map((item, index) => <li key={`socialIcons-${index}`}><a href={item.url} target={item.targetWindow}><i className={item.className} aria-hidden={item.ariaHidden}></i></a></li>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </nav>
                }

                <button className="header-menu-toggle" style={{ background: 'unset', border: 'unset' }} onClick={handleMenuClick}>
                    <span className="header-menu-text">Menu</span>
                    <span className="header-menu-icon"></span>
                </button>
            </header>
            <style jsx>{`
                @media (max-width: 768px) {
                    ${styleSheet.headerNav} {
                        width: 100%;
                        padding: 2.6rem 1.6rem;
                    }
                    ${styleSheet.headerNavList} {
                        fontSize: 1.5rem;
                    }
                    ${styleSheet.mediaNetworkLinks} {
                        flexDirection: column;
                        gap: 5px;
                    }
                }
            `}</style>
        </>
    )
}

export default Header;
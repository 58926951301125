import React from 'react';
import ClientSlider from '../components/ClientSlider.component';
// import TestimonialSlider from '../components/TestimonialSlider.component';

const data = {
    clientsList: [
        {
            name: "Mobileshop Ug",
            className: "clients__slide",
            image: "assets/images/clients/mobileshop.png",
            website: "https://mobileshop.ug/",
        },
        {
            name: "Jiangaze",
            className: "clients__slide",
            image: "assets/images/clients/jiangaze.png",
            website: "https://www.jiangaze.com/",
        },
        {
            name: "SiiA Group",
            className: "clients__slide",
            image: "assets/images/clients/siia.png",
            website: "https://www.siia.group/",
        },
        {
            name: "SoylentEdtech",
            className: "clients__slide",
            image: "assets/images/clients/soylentEdtech.png",
            website: "https://soylent.in/",
        },
        {
            name: "MtechFirm",
            className: "clients__slide",
            image: "assets/images/clients/mtechFirm.png",
            website: "https://mtechfirm.com/",
        },
        {
            name: "Webhood",
            className: "clients__slide",
            image: "assets/images/clients/webhood.png",
            website: "https://www.webhood.net/",
        }
    ],
    reviewsList: [
        {
            name: 'Tim Cook',
            designation: 'CEO, Apple',
            image: 'assets/images/avatars/user-01.jpg',
            message: 'Qui ipsam temporibus quisquam vel. Maiores eos cumque distinctio nam accusantium ipsum.  Laudantium quia consequatur molestias delectus culpa facere hic dolores aperiam. Accusantium quos qui praesentium corpori. Excepturi nam cupiditate culpa doloremque deleniti repellat.',
        },
        {
            name: 'Sundar Pichai',
            designation: 'CEO, Google',
            image: 'assets/images/avatars/user-05.jpg',
            message: 'Excepturi nam cupiditate culpa doloremque deleniti repellat. Veniam quos repellat voluptas animi adipisci. Nisi eaque consequatur. Quasi voluptas eius distinctio. Atque eos maxime. Qui ipsam temporibus quisquam vel.',
        },
        {
            name: 'Satya Nadella',
            designation: 'CEO, Microsoft',
            image: 'assets/images/avatars/user-02.jpg',
            message: 'Repellat dignissimos libero. Qui sed at corrupti expedita voluptas odit. Nihil ea quia nesciunt. Ducimus aut sed ipsam. Autem eaque officia cum exercitationem sunt voluptatum accusamus. Quasi voluptas eius distinctio.',
        }
    ]
}

const Clients = () => {
    return (
        <>
            <section id="clients" className="s-clients">
                <div className="row section-header" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">Our Clients</h3>
                        <h1 className="display-2">Connecting Ideas to Uniquely Crafted Experiences</h1>
                    </div>
                </div>
                <div className="row clients-outer" data-aos="fade-up">
                    <div className="col-full">
                        <div className="clients">
                            <ClientSlider data={ data?.clientsList || []}/>
                        </div>
                    </div>
                    <p className="lead">Ecorpin has been honored to partner up with these clients</p>
                </div>
                {/* <div className="row clients-testimonials" data-aos="fade-up">
                    <div className="col-full">
                        <div className="testimonials">
                            <TestimonialSlider data={ data?.reviewsList || []} />
                        </div>
                    </div>
                </div> */}
            </section>
        </>
    )
}

export default Clients;
import React from 'react';
import ContactForm from '../components/ContactForm.component';

const data = {
    mediaNetworkLinks: [
        {
            href: "#0",
            className: "fa fa-linkedin fa-2x",
            ariaHidden:"true",
            labelName: 'LinkedIn',
            url: "https://www.linkedin.com/company/ecorpin",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-github fa-2x",
            ariaHidden:"true",
            labelName: 'Github',
            url: "https://github.com/Ecorpin-Corp",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-paypal fa-2x",
            ariaHidden:"true",
            labelName: 'Paypal',
            url: "https://www.paypal.com/paypalme/bhagwansingh5602",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-skype fa-2x",
            ariaHidden:"true",
            labelName: 'Skype',
            url: "live:hritikr16_1",
            targetWindow: 'blank' 
        }, {
            href: "#0",
            className: "fa fa-youtube fa-2x",
            ariaHidden:"true",
            labelName: 'Youtube',
            url: "https://www.youtube.com/@ecorpincorporation6752",
            targetWindow: 'blank' 
        }
    ],
}
const Contact = () => {
    return (
        <>
            <section id="contact" className="s-contact">
                <div className="overlay"></div>
                <div className="contact__line"></div>
                <div className="row section-header" data-aos="fade-up">
                    <div className="col-full">
                        <h3 className="subhead">Have an Idea!</h3>
                        <h1 className="display-2 display-2--light">
                            We'd love to hear from you.
                        </h1>
                        <p>Let's embark on a journey of innovation with us!</p>
                    </div>
                </div>
                <div className="row contact-content" data-aos="fade-up">
                    <ContactForm />
                    <div className="contact-secondary">
                        <div className="contact-info">
                            <h3 className="h6 hide-on-fullwidth">Contact Info</h3>
                            <div className="cinfo">
                                <h5>
                                    Email Us At</h5>
                                <p>
                                    ecorpin.help@gmail.com
                                    <br />
                                    lab.ecorpin@gmail.com
                                    <br />
                                    {/* <s>business@ecorpin.com</s> */}
                                </p>
                            </div>
                            <div className="cinfo">
                                <h5>Call Us At</h5>
                                <p>
                                    Mobile: (+91) 62951 73968
                                    <br />
                                    Mobile: (+91) 79038 94771
                                </p>
                            </div>
                            <div className="cinfo">
                                <h5>Whatsaap Us At</h5>
                                <p>
                                    Number: (+91) 62951 73968
                                </p>
                            </div>
                            <ul className="contact-social">
                                {
                                    data?.mediaNetworkLinks && data?.mediaNetworkLinks?.map((item, index) => <li key={`socialIcons-${index}`}><a href={item.url} target={item.targetWindow}><i className={item.className} aria-hidden={item.ariaHidden}></i></a></li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}

export default Contact;
import React from 'react';
import { setWebClientData } from '../helpers/firebase';
import { showAppAlert } from '../helpers/commonHelpers';
import Loader from './Loader.component';

const ContactForm = () => {
    const [formData, setFormData] = React.useState({
        contactName: "",
        contactPhone: "",
        contactEmail: "",
        contactSubject: "",
        contactMessage: "",
    });
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let newVisitorData = {
                action_needed: true,
                fullName: formData?.contactName,
                mobileNumber: formData?.contactPhone,
                email: formData?.contactEmail,
                subject: formData?.contactSubject,
                message: formData?.contactMessage,
                managerRemark: "",
            };
            await setWebClientData(newVisitorData, setLoading, showAppAlert);
        }
    }

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Validate contact name
        if (!formData.contactName.trim()) {
            newErrors.contactName = "Please enter your full name";
            isValid = false;
        }

        // Validate contact phone
        if (!formData.contactPhone.trim()) {
            newErrors.contactPhone = "Please enter your phone/mobile number";
            isValid = false;
        }

        // Validate contact email
        if (!formData.contactEmail.trim()) {
            newErrors.contactEmail = "Please enter your email address";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.contactEmail)) {
            newErrors.contactEmail = "Please enter a valid email address";
            isValid = false;
        }

        // Validate contact message
        if (!formData.contactMessage.trim()) {
            newErrors.contactMessage = "Please enter a message";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    
    return (
        <div className="contact-primary">
            {loading && <Loader/>}
            {
                !loading &&
                <form name="contactForm" id="contactForm" onSubmit={handleFormSubmit} noValidate={'noValidate'}>
                    <fieldset>
                        <div className="form-field">
                            <input name="contactName" type="text" id="contactName" onChange={(e) => setFormData({ ...formData, contactName: e.target.value })} placeholder="Full name" value={formData?.contactName} required="" aria-required="true" className="full-width" />
                            {errors.contactName && <span style={{ color: 'red' }}>{errors.contactName}</span>}
                        </div>
                        <div className="form-field">
                            <input name="contactPhone" type="email" id="contactPhone" onChange={(e) => setFormData({ ...formData, contactPhone: e.target.value })} placeholder="Phone / mobile number " value={formData?.contactPhone} required="" aria-required="true" className="full-width" />
                            {errors.contactPhone && <span style={{ color: 'red' }}>{errors.contactPhone}</span>}
                        </div>
                        <div className="form-field">
                            <input name="contactEmail" type="email" id="contactEmail" onChange={(e) => setFormData({ ...formData, contactEmail: e.target.value })} placeholder="Email address" value={formData?.contactEmail} required="" aria-required="true" className="full-width" />
                            {errors.contactEmail && <span style={{ color: 'red' }}>{errors.contactEmail}</span>}
                        </div>
                        <div className="form-field">
                            <input name="contactSubject" type="text" id="contactSubject" onChange={(e) => setFormData({ ...formData, contactSubject: e.target.value })} placeholder="Subject of contact" value={formData?.contactSubject} className="full-width" />
                        </div>
                        <div className="form-field">
                            <textarea name="contactMessage" id="contactMessage" onChange={(e) => setFormData({ ...formData, contactMessage: e.target.value })} placeholder="Rough information about your Idea!" value={formData?.contactMessage} rows="8" cols="50" required="" aria-required="true" className="full-width"></textarea>
                            {errors.contactMessage && <span style={{ color: 'red' }}>{errors.contactMessage}</span>}
                        </div>
                        <div className="form-field">
                            <button className="full-width btn--primary">{' Submit '}</button>
                        </div>
                    </fieldset>
                </form>
            }
        </div>
    )
}

export default ContactForm;